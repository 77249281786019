export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCVquw6_N_hnTcukM3UMFqprholHoYeUfQ",
    authDomain: "axipapptest.firebaseapp.com",
    databaseURL: "https://axipapptest.firebaseio.com",
    projectId: "axipapptest",
    storageBucket: "axipapptest.appspot.com",
    messagingSenderId: "306447099330",
    appId: "1:306447099330:web:87a4d020464a237c",
    measurementId: "G-XD6NZ5639M"
  },
  stripe_publishable: "pk_test_vMBxbKGefK41zpgm77ILhIDA",
  gtm_id: "GTM-TG9LWHL"
};
