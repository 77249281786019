import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/shared/home/home.module').then(m => m.HomeModule),
    data: {title: 'Platfio'}
  },
  { path: 'contact', redirectTo: '/', pathMatch: 'full' },
  { path: 'support/thanks', redirectTo: '/thanks', pathMatch: 'full' },
  { path: 'contact/thanks', redirectTo: '/thanks', pathMatch: 'full' },
  {
    path: 'type',
    loadChildren: () => import('./pages/shared/typer/typer.module').then(m => m.TyperModule),
    data: {title: 'Select'}
  },
  {
    path: 'agenter',
    loadChildren: () => import('./pages/shared/agenter/agenter.module').then(m => m.AgenterModule),
    data: {title: 'Assign'}
  },
  {
    path: 'listings',
    loadChildren: () => import('./pages/shared/listings/listings.module').then(m => m.ListingsModule),
    data: {title: 'Assign'}
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/shared/login/login.module').then(m => m.LoginModule),
    data: {title: 'Login'}
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/shared/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup'}
  },
  {
    path: 'signup/agency',
    loadChildren: () => import('./pages/shared/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup'}
  },
  {
    path: 'signup/affiliate',
    loadChildren: () => import('./pages/shared/signup/signup.module').then(m => m.SignupModule),
    data: {title: 'Signup'}
  },
  {
    path: 'display',
    loadChildren: () => import('./pages/agency/app/video-display/display.module').then(m => m.DisplayModule),
    data: {title: 'Display'}
  },
  {
    path: 'myapp',
    loadChildren: () => import('./pages/app/myapp/myapp.module').then(m => m.MyappModule),
    data: {title: 'My App'}
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/shared/support/support.module').then(m => m.SupportModule),
    data: {title: 'Support'}
  },
  {
    path: 'plan',
    loadChildren: () => import('./pages/agency/app/wireframes/plan/plan.module').then(m => m.PlanModule),
    data: {title: 'Plan'}
  },
  {
    path: 'proposal',
    loadChildren: () => import('./pages/app/proposal/proposal.module').then(m => m.ProposalModule),
    data: {title: 'Proposal'}
  },
  {
    path: 'proposal/contract',
    loadChildren: () => import('./pages/app/proposal/contract/contract.module').then(m => m.ContractModule),
    data: {title: 'Contract'}
  },
  {
    path: 'thanks',
    loadChildren: () => import('./pages/shared/thanks/contact-thanks.module').then(m => m.ContactThanksModule),
    data: {title: 'Thanks'}
  },
  {
    path: 'myapp/invoices',
    loadChildren: () => import('./pages/app/invoices/invoices.module').then(m => m.InvoicesModule),
    data: {title: 'Invoices'}
  },
  {
    path: 'myapp/card',
    loadChildren: () => import('./pages/app/change-card/change-card.module').then(m => m.ChangeCardModule),
    data: {title: 'Card'}
  },
  {
    path: 'myapp/tasks',
    loadChildren: () => import('./pages/app/tasks/tasks.module').then(m => m.TasksModule),
    data: {title: 'Card'}
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/shared/privacy/privacy.module').then(m => m.PrivacyModule),
    data: {title: 'Privacy'}
  },
  {
    path: 'learn',
    loadChildren: () => import('./pages/shared/learn/learn.module').then(m => m.LearnModule),
    data: {title: 'Learn'}
  },
  {
    path: 'myapp/email/unsubscribe',
    loadChildren: () => import('./pages/shared/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
    data: {title: 'Unsubscribe'}
  },
  {
    path: 'myaffiliate',
    loadChildren: () => import('./pages/agency/affiliate/myaffiliate.module').then(m => m.MyaffiliateModule),
    data: {title: 'My Affiliate'}
  },
  {
    path: 'myagency',
    loadChildren: () => import('./pages/agency/agency/myagency.module').then(m => m.MyagencyModule),
    data: {title: 'My Agency'}
  },
  {
    path: 'myagency/leads',
    loadChildren: () => import('./pages/agency/leads/leads.module').then(m => m.LeadsModule),
    data: {title: 'My Agency Leads'}
  },
  {
    path: 'myagency/tasks',
    loadChildren: () => import('./pages/agency/tasks/team.module').then(m => m.TeamModule),
    data: {title: 'My Agency Tasks'}
  },
  {
    path: 'myagency/notes',
    loadChildren: () => import('./pages/agency/notes/notes.module').then(m => m.NotesModule),
    data: {title: 'My Agency Notes'}
  },
  {
    path: 'myagency/settings',
    loadChildren: () => import('./pages/agency/settings/settings.module').then(m => m.SettingsModule),
    data: {title: 'My Agency Settings'}
  },
  {
    path: 'myagency/team',
    loadChildren: () => import('./pages/agency/settings/settings.module').then(m => m.SettingsModule),
    data: {title: 'My Agency Team'}
  },
  {
    path: 'myagency/marketing',
    loadChildren: () => import('./pages/agency/settings/settings.module').then(m => m.SettingsModule),
    data: {title: 'My Agency Marketing'}
  },
  {
    path: 'myagency/tools',
    loadChildren: () => import('./pages/agency/settings/settings.module').then(m => m.SettingsModule),
    data: {title: 'My Agency Tools'}
  },
  {
    path: 'myagency/reports',
    loadChildren: () => import('./pages/agency/reports/reports.module').then(m => m.ReportsModule),
    data: {title: 'My Agency Reports'}
  },
  {
    path: 'myagency/accounts',
    loadChildren: () => import('./pages/agency/developer-accounts/developers-index/myaccounts.module').then(m => m.MyaccountsModule),
    data: {title: 'Developer Accounts'}
  },
  {
    path: 'myagency/developer-appstore',
    loadChildren: () => import('./pages/agency/developer-accounts/developer-appstore/developer-appstore.module').then(m => m.DeveloperAppstoreModule),
    data: {title: 'Appstore Developer'}
  },
  {
    path: 'myagency/developer-playstore',
    loadChildren: () => import('./pages/agency/developer-accounts/developer-playstore/developer-playstore.module').then(m => m.DeveloperPlaystoreModule),
    data: {title: 'Playstore Developer'}
  },
  {
    path: 'myagency/developer-google',
    loadChildren: () => import('./pages/agency/developer-accounts/developer-google/developer-google.module').then(m => m.DeveloperGoogleModule),
    data: {title: 'Google Developer'}
  },
  {
    path: 'myagency/autoresponder',
    loadChildren: () => import('./pages/agency/autoresponders/autoresponders.module').then(m => m.AutorespondersModule),
    data: {title: 'My Agency Tasks'}
  },
  // Email Templates and Sequences
  {
    path: 'myagency/template',
    loadChildren: () => import('./pages/agency/autoresponders/templates/template-index/template-index.module').then(m => m.TemplateIndexModule),
    data: {title: 'My Agency Autoprocess template'}
  },
  {
    path: 'myagency/template/:tid',
    loadChildren: () => import('./pages/agency/autoresponders/templates/template-crud/template-crud.module').then(m => m.TemplateCrudModule),
    data: {title: 'My Agency Autoprocess Template'}
  },
  {
    path: 'myagency/sequence',
    loadChildren: () => import('./pages/agency/autoresponders/sequeces/sequenc-index/sequenc-index.module').then(m => m.SequencIndexModule),
    data: {title: 'My Agency Autoprocess template'}
  },
  {
    path: 'myagency/sequence/:sid',
    loadChildren: () => import('./pages/agency/autoresponders/sequeces/sequenc-crud/sequenc-crud.module').then(m => m.SequencCrudModule),
    data: {title: 'My Agency Autoprocess Template'}
  },
  {
    path: 'myagency/sender',
    loadChildren: () => import('./pages/agency/autoresponders/senders/sender-index/sender-index.module').then(m => m.SenderIndexModule),
    data: {title: 'My Agency Autoprocess Sender'}
  },
  {
    path: 'myagency/sender/:sid',
    loadChildren: () => import('./pages/agency/autoresponders/senders/sender-crud/sender-crud.module').then(m => m.SenderCrudModule),
    data: {title: 'My Agency Autoprocess Sender'}
  },
  {
    path: 'myagency/resources',
    loadChildren: () => import('./pages/agency/resources/resources.module').then(m => m.ResourcesModule),
    data: {title: 'Resources'}
  },
  {
    path: 'myagency/resources/:rid',
    loadChildren: () => import('./pages/agency/resources/resource-detail/resource-detail.module').then(m => m.ResourceDetailModule),
    data: {title: 'Resources'}
  },
  {
    path: 'myaffiliate/resources',
    loadChildren: () => import('./pages/agency/resources/resources.module').then(m => m.ResourcesModule),
    data: {title: 'Resources'}
  },
  {
    path: 'myagency/forms',
    loadChildren: () => import('./pages/agency/forms/forms-index/forms.module').then(m => m.AFormsModule),
    data: {title: 'Forms'}
  },
  {
    path: 'myagency/forms/detail',
    loadChildren: () => import('./pages/agency/forms/form-detail/form-detail.module').then(m => m.FormDetailModule),
    data: {title: 'Forms'}
  },
  {
    path: 'myagency/landings',
    loadChildren: () => import('./pages/agency/landing/landing-index/landing.module').then(m => m.LandingModule),
    data: {title: 'Forms'}
  },
  {
    path: 'myagency/landings/detail',
    loadChildren: () => import('./pages/agency/landing/landing-detail/landing-detail.module').then(m => m.LandingDetailModule),
    data: {title: 'Forms'}
  },
  {
    path: 'myagency/ads',
    loadChildren: () => import('./pages/agency/ads/ads/ads.module').then(m => m.AdsModule),
    data: {title: 'Ads'}
  },
  {
    path: 'myagency/forms/embed',
    loadChildren: () => import('./pages/app/embedding/forms/forms.module').then(m => m.EFormsModule),
    data: {title: 'Forms'}
  },
  {
    path: 'myagency/terms',
    loadChildren: () => import('./pages/agency/terms/terms-index/terms-index.module').then(m => m.TermsIndexModule),
    data: {title: 'My Agency Terms'}
  },
  {
    path: 'myagency/terms/:tid',
    loadChildren: () => import('./pages/agency/terms/terms-crud/terms-crud.module').then(m => m.TermsCrudModule),
    data: {title: 'My Agency Terms'}
  },
  {
    path: 'myagency/:pid',
    loadChildren: () => import('./pages/agency/app/project/myproject.module').then(m => m.MyprojectModule),
    data: {title: 'My Agency Project'}
  },
  {
    path: 'myagency/:pid/emulator',
    loadChildren: () => import('./pages/agency/app/emulator/emulator.module').then(m => m.EmulatorModule),
    data: {title: 'Emulator'}
  },
  {
    path: 'myagency/:pid/builds',
    loadChildren: () => import('./pages/agency/app/build/build.module').then(m => m.BuildModule),
    data: {title: 'My Agency Project Builds'}
  },
  {
    path: 'myagency/:pid/wireframes',
    loadChildren: () => import('./pages/agency/app/wireframes/wireframes.module').then(m => m.WireframesModule),
    data: {title: 'My Agency Project Wireframes'}
  },
  {
    path: 'myagency/:aid/webpages',
    loadChildren: () => import('./pages/agency/app/webpages/webpages.module').then(m => m.WebpagesModule),
    data: {title: 'My Agency Project Webpages'}
  },
  {
    path: 'myagency/:pid/:prid',
    loadChildren: () => import('./pages/agency/app/proposal/proposal.module').then(m => m.ProposalModule),
    data: {title: 'My Agency Project Proposal'}
  },
  {
    path: 'share-agency',
    loadChildren: () => import('./pages/agency/share-agency/share-agency.module').then(m => m.ShareAgencyModule),
    data: {title: 'My Agency Project Proposal'}
  },
  // manager and crud
  {
    path: 'tools/lessons',
    loadChildren: () => import('./pages/staff/lessons/index/index.module').then(m => m.IndexModule),
    data: {title: 'Lessons'}
  },
  {
    path: 'lesson/manager',
    loadChildren: () => import('./pages/staff/lessons/lesson-manager-crud/lesson-manager-crud.module').then(m => m.LessonManagerCrudModule),
    data: {title: "LessonManager"}
  },

  // index and detail
  {
    path: 'lessons',
    loadChildren: () => import('./pages/shared/lessons/lesson-index/lesson-index.module').then(m => m.LessonIndexModule),
    data: {title: 'Lessons'}
  },
  // {
  //   path: 'lesson/:lid',
  //   loadChildren: () => import('./pages/learn/lessons/detail/detail.module').then(m => m.DetailModule),
  //   data: {title: 'Lesson'}
  // },
  {
    path: 'lesson/:lid',
    loadChildren: () => import('./pages/shared/lessons/lesson-detail/lesson-detail.module').then(m => m.LessonDetailModule),
    data: {title: 'Lesson'}
  },
  {
    path: 'tools/lesson/:id',
    loadChildren: () => import('./pages/staff/lessons/lesson-crud/lesson-crud.module').then(m => m.LessonCrudModule),
    data: {title: "Lesson"}
  },
  {
    path: 'integrations',
    loadChildren: () => import('./pages/agency/integrations/integrations.module').then(m => m.IntegrationsModule),
    data: {title: 'Integrations'}
  },
  {
    path: 'appstore-support',
    loadChildren: () => import('./pages/app/appstore-support/appstore-support.module').then(m => m.AppstoreSupportModule),
    data: {title: 'Appstore Support'}
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/shared/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
    data: {title: 'Terms And Condition'}
  },
  {
    path: 'embedding',
    loadChildren: () => import('./pages/app/embedding/pricing-embed/pricing-embed.module').then(m => m.PricingEmbedModule),
    data: {title: 'Embedding'}
  },
  {
    path: 'card',
    loadChildren: () => import('./pages/shared/card-payment/card-payment.module').then(m => m.CardPaymentModule),
    data: {title: 'Card'}
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/shared/account/account.module').then(m => m.AccountUserModule),
    data: {title: 'Account'}
  },
  {
    path: 'task/:tid',
    loadChildren: () => import('./pages/shared/task/add-tasks.module').then(m => m.AddTasksModule),
    data: {title: 'Task'}
  },
  {
    path: 'tasks/report',
    loadChildren: () => import('./pages/staff/report/tasks-report/tasks-report.module').then(m => m.TasksReportModule),
    data: {title: 'Task Report'}
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/app/landing/landing.module').then(m => m.LandingDModule),
    data: {title: ''}
  },
  {
    path: 'campaigns/:cid',
    loadChildren: () => import('./pages/agency/source-editor/source-editor.module').then(m => m.CampaignCrudModule),
    data: {title: 'Campaigns'}
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/agency/app/create/create.module').then(m => m.CreateModule),
    data: {title: 'Create'}
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/agency/app/config/config.module').then(m => m.ConfigModule),
    data: {title: 'Config'}
  },
  {
    path: 'funnel-analytics',
    loadChildren: () => import('./pages/agency/app/funnel-analytics/funnel-analytics.module').then(m => m.FunnelAnalyticsModule),
    data: {title: 'Funnel Analytics'}
  },
  {
    path: 'funnel-summary',
    loadChildren: () => import('./pages/agency/funnel/funnel-summary/funnel-summary.module').then(m => m.FunnelSummaryModule),
    data: {title: 'Funnel Summary'}
  },
  {
    path: 'runner/:rid',
    loadChildren: () => import('./pages/agency/app/runner/runner.module').then(m => m.RunnerModule),
    data: {title: 'Runner'}
  },
  {
    path: 'runners/report',
    loadChildren: () => import('./pages/staff/report/runners-report/runners-report.module').then(m => m.RunnersReportModule),
    data: {title: 'Runner Report'}
  },
  {
    path: 'bugs/report',
    loadChildren: () => import('./pages/staff/bugs/bugs/bugs-report.module').then(m => m.BugsReportModule),
    data: {title: 'Bugs Report'}
  },
  {
    path: 'bug/:bid',
    loadChildren: () => import('./pages/staff/bugs/bug/bug-crud.module').then(m => m.BugCrudModule),
    data: {title: 'Bugs Report'}
  },
  {
    path: 'staff/report',
    loadChildren: () => import('./pages/staff/report/report.module').then(m => m.ReportModule),
    data: {title: 'Report'}
  },
  {
    path: 'staff',
    loadChildren: () => import('./pages/staff/users/staff-users.module').then(m => m.StaffUsersModule),
    data: {title: 'Users'}
  },
  {
    path: 'staff/tools',
    loadChildren: () => import('./pages/staff/tools/staff-index.module').then(m => m.StaffIndexModule),
    data: {title: 'Users'}
  },
  {
    path: 'tools/helper',
    loadChildren: () => import('./pages/staff/helpers/helper-manager/helper.module').then(m => m.HelperModule),
  },
  {
    path:'helper/:hid',
    loadChildren: () => import('./pages/staff/helpers/helper-crud/helper-crud.module').then(m => m.HelperCrudModule),
  },
  {
    path: 'staff/machines',
    loadChildren: () => import('./pages/staff/machines/machines.module').then(m => m.MachinesModule),
    data: {title: 'Machines'}
  },
  // manager and crud
  {
    path: 'tools/announcements',
    loadChildren: () => import('./pages/staff/announcements/announcements/announcements.module').then(m => m.AnnouncementsModule),
    data: {title: 'Announcements'}
  },
  {
    path: 'tools/announcement/:aid',
    loadChildren: () => import('./pages/staff/announcements/announcement/announcement.module').then(m => m.AnnouncementModule),
    data: {title: 'Announcement'}
  },
  // index and detail
  {
    path: 'announcements',
    loadChildren: () => import('./pages/shared/announcements/announcement-index/announcement-index.module').then(m => m.AnnouncementIndexModule),
    data: {title: 'Announcements'}
  },
  {
    path: 'announcements/announcement-detail',
    loadChildren: () => import('./pages/shared/announcements/announcement-detail/announcement-detail.module').then(m => m.AnnouncementDetailModule),
    data: {title: 'Announcement'}
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/shared/media-view/media-view.module').then(m => m.MediaViewModule),
    data: {title: 'Media'}
  },
  {
    path: 'packages',
    loadChildren: () => import('./pages/staff/packages/packages/packages.module').then(m => m.PackagesModule),
    data: {title: 'Packages'}
  },
  {
    path: 'package/:pid',
    loadChildren: () => import('./pages/staff/packages/package/package.module').then(m => m.PackageModule),
    data: {title: 'Package'},
  },
  {
    path: 'package/:pid/assets/:aid',
    loadChildren: () => import('./pages/staff/packages/assets/assets.module').then(m => m.AssetsModule),
    data: {title: 'Assets'}
  },
  {
    path: 'releases',
    loadChildren: () => import('./pages/staff/releases/releases/releases.module').then(m => m.ReleasesModule),
    data: {title: 'Releases'}
  },
  {
    path: 'release/:rid',
    loadChildren: () => import('./pages/staff/releases/release/release.module').then(m => m.ReleaseModule),
    data: {title: 'Releases'}
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/staff/resources/resource/resource.module').then(m => m.ResourceModule),
    data: {title: 'Resources'}
  },
  {
    path: 'resource/:rid',
    loadChildren: () => import('./pages/staff/resources/resource-manager/resource-manager.module').then(m => m.ResourceManagerModule),
    data: {title: 'Resources'}
  },
  {
    path: 'resources/type/:rid',
    loadChildren: () => import('./pages/staff/resources/resource-type/resource-type.module').then(m => m.ResourceTypeModule),
    data: {title: 'Resources'}
  },
  {
    path: 'resources/typelist',
    loadChildren: () => import('./pages/staff/resources/resource-type-list/resource-type-list.module').then(m => m.ResourceTypeListModule),
    data: {title: 'Resources'}
  },
  {
    path: 'term',
    loadChildren: () => import('./pages/staff/terms/terms/terms.module').then(m => m.TermsModule),
    data: {title: 'Terms'}
  },
  {
    path: 'term/:tid',
    loadChildren: () => import('./pages/staff/terms/terms-manager/terms-manager.module').then(m => m.TermsManagerModule),
    data: {title: 'Terms'}
  },
  {
    path: 'funnel',
    loadChildren: () => import('./pages/agency/funnel/funnel-index/funnel-index.module').then(m => m.FunnelIndexModule),
    data: {title: 'Funnel'}
  },
  {
    path: 'funnel/:fid',
    loadChildren: () => import('./pages/agency/funnel/funnel-crud/funnel-crud.module').then(m => m.FunnelCrudModule),
    data: {title: 'Funnel'}
  },
  {
    path: 'industry/:iid',
    loadChildren: () => import('./pages/agency/product/industry/industries-crud/industries-crud.module').then(m => m.IndustriesCrudModule),
    data: {title: 'Industry'}
  },
  {
    path: 'industry',
    loadChildren: () => import('./pages/agency/product/industry/industry-index/industry-index.module').then(m => m.IndustryIndexModule),
    data: {title: 'Industry'}
  },
  {
    path: 'business/:bid',
    loadChildren: () => import('./pages/agency/product/business/businesses-crud/businesses-crud.module').then(m => m.BusinessesCrudModule),
    data: {title: 'Business'}
  },
  {
    path: 'business',
    loadChildren: () => import('./pages/agency/product/business/business-index/business-index.module').then(m => m.BusinessIndexModule),
    data: {title: 'Business'}
  },
  {
    path: 'template/:uid',
    loadChildren: () => import('./pages/agency/product/template/templates-crud/templates-crud.module').then(m => m.TemplatesCrudModule),
    data: {title: 'Template'}
  },
  {
    path: 'templates',
    loadChildren: () => import('./pages/agency/product/template/templates-index/templates-index.module').then(m => m.TemplatesIndexModule),
    data: {title: 'Template'}
  },
  {
    path: 'lessontype',
    loadChildren: () => import('./pages/staff/lessons/lesson-type-manager/lesson-type-manager.module').then(m => m.LessonTypeManagerModule),
    data: {title: 'LessonType'}
  },
  {
    path: 'lessontype/:lid',
    loadChildren: () => import('./pages/staff/lessons/lesson-type-crud/lesson-type-crud.module').then(m => m.LessonTypeCrudModule),
    data: {title: 'LessonType'}
  },
  {
    path: 'lessontag',
    loadChildren: () => import('./pages/staff/lessons/lesson-tag-manager/lesson-tag-manager.module').then(m => m.LessonTagManagerModule),
    data: {title: 'LessonTag'}
  },
  {
    path: 'lessontag/:lid',
    loadChildren: () => import('./pages/staff/lessons/lesson-tag-crud/lesson-tag-crud.module').then(m => m.LessonTagCrudModule),
    data: {title: 'LessonTag'}
  },
  {
    path: 'cold-message-manager',
    loadChildren: () => import('./pages/agency/prompts/cold-message-manager/cold-message-manager.module').then(m => m.ColdMessageManagerModule),
    data: {title: 'ColdMessage'}
  },
  {
    path: 'cold-message-crud/:cid',
    loadChildren: () => import('./pages/agency/prompts/cold-message-crud/cold-message-crud.module').then(m => m.ColdMessageCrudModule),
    data: {title: 'ColdMessage'}
  },
  {
    path: 'test-index/:uid',
    loadChildren: () => import('./pages/agency/app/tests/test-index/test-index.module').then(m => m.TestIndexModule),
  },
  {
    path: 'manual-test-crud',
    loadChildren: () => import('./pages/agency/app/tests/manual-test-crud/manual-test-crud.module').then(m => m.ManualTestCrudModule),
  },
  {
    path: 'manual-test-crud/:mtid',
    loadChildren: () => import('./pages/agency/app/tests/manual-test-crud/manual-test-crud.module').then(m => m.ManualTestCrudModule),
  },
  {
    path: 'automated-test-crud',
    loadChildren: () => import('./pages/agency/app/tests/automated-test-crud/automated-test-crud.module').then(m => m.AutomatedTestCrudModule),
  },
  {
    path: 'automated-test-crud/:atid',
    loadChildren: () => import('./pages/agency/app/tests/automated-test-crud/automated-test-crud.module').then(m => m.AutomatedTestCrudModule),
  },
  {
    path: 'test-results/:tid',
    loadChildren: () => import('./pages/agency/app/tests/test-result/test-result.module').then(m => m.TestResultModule),
  },
  {
    path:'test-results/:tid/:trid',
    loadChildren: () => import('./pages/agency/app/tests/test-result-detail/test-result-detail.module').then(m => m.TestResultDetailModule),
  },
  {
    path: 'signup/agency/onboarding',
    loadChildren: () => import('./pages/agency/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'test-types',
    loadChildren: () => import('./pages/agency/app/tests/test-types/test-types.module').then(m => m.TestTypesModule)
  },
  {
    path: 'myapp/wizard',
    loadChildren: () => import('./pages/app/wizard/wizard.module').then(m => m.WizardModule)
  },
  {
    path: 'myapp/note',
    loadChildren: () => import('./pages/shared/note/note.module').then(m => m.NoteModule)
  },
  {
    path: 'businesses',
    loadChildren: () => import('./pages/shared/templates/businesses/businesses.module').then(m => m.BusinessesModule)
  },
  {
    path: 'businesses/:bid',
    loadChildren: () => import('./pages/shared/templates/business/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'businesses/:bid/:tid',
    loadChildren: () => import('./pages/shared/templates/template/template.module').then(m => m.TemplateModule)
  },
  { path: 'ads', loadChildren: () => import('./pages/agency/ads/ads/ads.module').then(m => m.AdsModule) },
  {
    path: '**',
    loadChildren: () => import('./pages/shared/not-found/notfound.module').then(m => m.NotfoundModule),
    data: {title: 'Not Found'}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
