import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';
import { AnalyticsService } from './services/analytics.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import { UserManagementService } from './services/user-management.service';
import { register } from "swiper/element/bundle";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { HttpClientModule } from '@angular/common/http';
import { provideCharts, withDefaultRegisterables, } from 'ng2-charts';

register();

const f = environment.firebaseConfig;
const gtm = environment.gtm_id;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(f),
    AngularFireAnalyticsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PopoverModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TimepickerModule.forRoot(),
  ],
  providers: [
    AnalyticsService,
    UserManagementService,
    { provide: 'googleTagManagerId', useValue: gtm },
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
