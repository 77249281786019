import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  readonly apiAssembler = `https://template-assembler-npifkwbvzq-uc.a.run.app`

  affiliateId: string | null = null;
  affiliateCampaign = 'starter';
  tagLogs: any[] = [];

  uid: any = null;

  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              public titleService: Title,
              public httpClient: HttpClient,
              public afs: AngularFirestore,
              public analytics: AngularFireAnalytics,
              public afAuth: AngularFireAuth,
              public gtm: GoogleTagManagerService) {
    this.afAuth.user.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.log('user loggedin thus uid');
      } else {
        this.activatedRoute.queryParams.subscribe(params => {
          if (params.uid) {
            this.uid = params.uid;
            this.log('user assigned by uid param');
          }
          if (params.email) {
            let sub = this.afs.collection('users', ref => ref.where('email', '==', params.email)).valueChanges().subscribe((users: any[]) => {
              sub.unsubscribe();
              if (users.length) {
                this.uid = users[0].uid
                this.log('user found and assigned from email param');
              }
            })
          }
        })
      }
    })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const gtmTag: any = {
          event: 'page',
          pageName: event.urlAfterRedirects
        };
        try {
          this.gtm.pushTag(gtmTag);
        } catch (err) {

        }
        this.log('route after url redirect');
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    ).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`${title}`);
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.utm_source && params.utm_medium === 'affiliate') {
        console.log('set affiliate: ' + params.utm_source);
        this.affiliateId = params.utm_source;
        this.affiliateCampaign = params.utm_campaign;
      }
    });

    window.onbeforeunload = () => {
      this.log('unload')
    }
  }

  log(description: string) {
    if (this.uid) {
      this.afs.collection('users/' + this.uid + '/logs').add({
        route: this.router.url,
        time: new Date(),
        description: description ? description : ''
      })
    }
  }

  sendEvent(data: any, name: string): void {
    // construct event tag
    const gtmTag: any = {
      event: name,
      ...data,
    };
    // send tag to tag manager
    this.gtm.pushTag(gtmTag);
    // add tag to logs
    gtmTag.time = new Date();
    this.tagLogs.push(gtmTag);
  }

  startBuild(uid: string) {
    return this.httpClient.post(`${this.apiAssembler}/v1/handle_new`, {
      uid: uid
    })
  }

}
