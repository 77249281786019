<router-outlet></router-outlet>

<div class="update-popup" *ngIf="showPopup">
  <button class="close-btn" (click)="closePopup()">✕</button><br>
  <div class="d-flex align-items-center justify-content-center">
    <div class="popup-content">
      <p class="popup-title">Update available</p>
      <p class="popup-message fs-6">Please refresh to get the latest version</p>
    </div>
    <button class="refresh-btn" (click)="refreshPage()">Refresh now</button>
  </div>
</div>
